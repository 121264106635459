@font-face {
  src: 
  url('../assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
  url('../assets/fonts/Roboto/Roboto-Regular.woff') format('woff'),
  url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype')
;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  font-family: Roboto;
}
@font-face {
  src: 
  url('../assets/fonts/Roboto/Roboto-Semibold.woff2') format('woff2'),
  url('../assets/fonts/Roboto/Roboto-Semibold.woff') format('woff'),
  url('../assets/fonts/Roboto/Roboto-Semibold.ttf') format('truetype')
;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  font-family: Roboto-Semibold;
}
@font-face {
  src: 
  url('../assets/fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
  url('../assets/fonts/Roboto/Roboto-Bold.woff') format('woff'),
  url('../assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype')
;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  font-family: Roboto-Bold;
}