._112d34e0 {
  display: grid;
  gap: var(--rk2f622);
  grid-template-columns: repeat(12, 1fr);
  margin: auto;
  @media (max-width: 1279px) {
    margin: var(--rk2f622);
    width: auto;
  }
  @media (max-width: 1023px) {
    grid-template-columns: repeat(8, 1fr);
  }
  @media (max-width: 599px) {
    margin: var(--rk2f620);
    gap: var(--rk2f620);
    grid-template-columns: repeat(4, 1fr);
  }
}
._112d34e0:focus {
  outline: var(--rk2f6w);
}