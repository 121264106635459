._1dnejn40 {
  height: var(--rk2f623);
  padding: var(--rk2f61x) var(--rk2f61z);
  font-size: var(--rk2f611);
  line-height: var(--rk2f614);
  letter-spacing: var(--rk2f61j);
}
._1dnejn41 {
  height: var(--rk2f626);
  padding: var(--rk2f61z) var(--rk2f620);
  font-size: var(--rk2f612);
  line-height: var(--rk2f615);
  letter-spacing: var(--rk2f61k);
}
._1dnejn42 {
  border: 0;
  border-radius: var(--rk2f61r);
  cursor: pointer;
  min-width: 9.25rem;
  font-family: var(--rk2f6d);
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
._1dnejn43 {
  margin: 0 var(--rk2f61y);
}
._1dnejn44 {
  color: var(--ds-color-text-brandMain);
}
._1dnejn45 {
  color: var(--ds-color-text-brandMain);
}
._1dnejn42:focus {
  outline: var(--rk2f6w);
  box-shadow: var(--rk2f6v);
}
._1dnejn45:focus {
  outline: var(--rk2f6y);
  box-shadow: var(--rk2f6x);
}
._1dnejn42:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}
._1dnejn42:disabled {
  cursor: not-allowed;
  color: var(--ds-color-text-disabled);
}
._1dnejn46 {
  background-color: var(--ds-color-fill-brandPure);
  color: var(--ds-color-text-inverseHighlight);
}
._1dnejn47 {
  background-color: var(--ds-color-opacity-transparent);
  color: var(--ds-color-text-brandMain);
  border: 1px solid var(--ds-color-border-brandMain);
}
._1dnejn48 {
  background-color: var(--ds-color-opacity-transparent);
  color: var(--ds-color-text-brandMain);
  border-radius: 0;
}
._1dnejn49 {
  background-color: var(--ds-color-opacity-transparent);
  color: var(--ds-color-text-brandMain);
  border-radius: 0;
  min-width: auto;
  padding-left: 0;
  padding-right: 0;
}
._1dnejn4a {
  background-color: var(--ds-color-fill-inverseMain);
  color: var(--ds-color-text-brandMain);
}
._1dnejn4b {
  background-color: var(--ds-color-opacity-transparent);
  color: var(--ds-color-text-inversePrimary);
  border: 1px solid var(--ds-color-border-inversePure);
}
._1dnejn4c {
  background-color: var(--ds-color-opacity-transparent);
  color: var(--ds-color-text-inversePrimary);
  border-radius: 0;
}
._1dnejn4d {
  background-color: var(--ds-color-opacity-transparent);
  color: var(--ds-color-text-inversePrimary);
  border-radius: 0;
  min-width: auto;
  padding-left: 0;
  padding-right: 0;
}
._1dnejn46:hover:not(:disabled) {
  background-color: var(--ds-color-fill-brandHover);
  color: var(--ds-color-text-inverseHighlight);
}
._1dnejn46:active:not(:disabled) {
  background-color: var(--ds-color-fill-brandActive);
  color: var(--ds-color-text-inverseHighlight);
}
._1dnejn46:disabled {
  background-color: var(--ds-color-fill-disabled);
  color: var(--ds-color-text-disabled);
}
._1dnejn4a:hover:not(:disabled) {
  background-color: var(--ds-color-fill-hover);
  color: var(--ds-color-text-brandHover);
}
._1dnejn4a:active:not(:disabled) {
  background-color: var(--ds-color-fill-hover);
  color: var(--ds-color-text-inverseBrandActive);
}
._1dnejn4a:disabled {
  background-color: var(--ds-color-fill-inverseDisabled);
  color: var(--ds-color-text-inverseDisabled);
}
._1dnejn47:hover:not(:disabled) {
  background-color: var(--ds-color-fill-brandHover);
  color: var(--ds-color-text-inverseHighlight);
  border-width: 0;
}
._1dnejn47:active:not(:disabled) {
  background-color: var(--ds-color-fill-brandActive);
  color: var(--ds-color-text-inverseHighlight);
}
._1dnejn47:disabled {
  border: 1px solid var(--ds-color-border-low);
  color: var(--ds-color-text-disabled);
}
._1dnejn4b:hover:not(:disabled) {
  background-color: var(--ds-color-fill-hover);
  color: var(--ds-color-text-brandMain);
  border-width: 1px;
}
._1dnejn4b:active:not(:disabled) {
  background-color: var(--ds-color-fill-hover);
  color: var(--ds-color-text-inverseBrandActive);
}
._1dnejn4b:disabled {
  border: 1px solid var(--ds-color-border-inverseLow);
  color: var(--ds-color-text-inverseDisabled);
}
._1dnejn48:hover:not(:disabled) {
  color: var(--ds-color-text-brandHover);
}
._1dnejn48:active:not(:disabled) {
  color: var(--ds-color-text-brandActive);
}
._1dnejn48:disabled {
  color: var(--ds-color-text-disabled);
}
._1dnejn4c:hover:not(:disabled) {
  color: var(--ds-color-text-brandMain);
}
._1dnejn4c:active:not(:disabled) {
  color: var(--ds-color-text-inverseBrandActive);
}
._1dnejn4c:disabled {
  color: var(--ds-color-text-inverseDisabled);
}
._1dnejn49:hover:not(:disabled) {
  color: var(--ds-color-text-brandHover);
}
._1dnejn49:active:not(:disabled) {
  color: var(--ds-color-text-brandActive);
}
._1dnejn49:disabled {
  color: var(--ds-color-text-disabled);
}
._1dnejn4d:hover:not(:disabled) {
  color: var(--ds-color-text-brandMain);
}
._1dnejn4d:active:not(:disabled) {
  color: var(--ds-color-text-inverseBrandActive);
}
._1dnejn4d:disabled {
  color: var(--ds-color-text-inverseDisabled);
}