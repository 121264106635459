*, *:before, *:after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html, body {
  height: 100%;
}
body {
  -webkit-font-smoothing: antialiased;
  font-family: var(--rk2f6c);
  font-size: 16px;
  line-height: var(--rk2f615);
}
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
}
p, h1, h2, h3, h4, h5, h6, span {
  overflow-wrap: break-word;
  font-weight: normal;
}
#root, #__next {
  isolation: isolate;
}