:root {
  --rk2f60: blur(8px);
  --rk2f61: blur(16px);
  --rk2f62: blur(24px);
  --rk2f63: blur(40px);
  --rk2f64: 0px;
  --rk2f65: 1px;
  --rk2f66: 2px;
  --rk2f67: 4px;
  --rk2f68: @media (max-width: 1439px);
  --rk2f69: @media (max-width: 1279px);
  --rk2f6a: @media (max-width: 1023px);
  --rk2f6b: @media (max-width: 599px);
  --rk2f6c: Roboto, sans-serif;
  --rk2f6d: Roboto-Semibold;
  --rk2f6e: Roboto-Bold;
  --rk2f6f: 0px 0px 1px rgba(0, 8, 36, 0.08), 0px 0.5px 2px rgba(26, 26, 30, 0.16);
  --rk2f6g: 0px 0px 1px rgba(0, 8, 36, 0.04), 0px 2px 4px rgba(26, 26, 30, 0.16);
  --rk2f6h: 0px 0px 2px rgba(0, 8, 36, 0.04), 0px 4px 8px rgba(26, 26, 30, 0.16);
  --rk2f6i: 0px 2px 4px rgba(0, 8, 36, 0.04), 0px 8px 16px rgba(26, 26, 30, 0.16);
  --rk2f6j: 0px 2px 8px rgba(0, 8, 36, 0.04), 0px 16px 24px rgba(26, 26, 30, 0.16);
  --rk2f6k: 0px 2px 8px rgba(0, 8, 36, 0.08), 0px 20px 32px rgba(26, 26, 30, 0.24);
  --rk2f6l: inset 0px 0.5px 4px rgba(26, 26, 30, 0.32);
  --rk2f6m: 0px 0px 0px rgba(0, 8, 36, 0), 0px 0px 0px rgba(26, 26, 30, 0);
  --rk2f6n: 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 0.5px 2px rgba(0, 0, 0, 0.32);
  --rk2f6o: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.32);
  --rk2f6p: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.32);
  --rk2f6q: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.32);
  --rk2f6r: 0px 2px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.32);
  --rk2f6s: 0px 2px 8px rgba(0, 0, 0, 0.08), 0px 20px 32px rgba(0, 0, 0, 0.32);
  --rk2f6t: inset 0px 0.5px 4px rgba(0, 0, 0, 0.32);
  --rk2f6u: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0);
  --rk2f6v: 0px 0px 0px 2px rgba(0, 0, 0, 0.3);
  --rk2f6w: 1.5px solid #000824;
  --rk2f6x: 0px 0px 0px 2px rgba(255, 255, 255, 0.3);
  --rk2f6y: 1.5px solid #FFA4C2;
  --rk2f6z: 0.625rem;
  --rk2f610: 0.75rem;
  --rk2f611: 0.875rem;
  --rk2f612: 1rem;
  --rk2f613: 1.125rem;
  --rk2f614: 1.25rem;
  --rk2f615: 1.5rem;
  --rk2f616: 1.75rem;
  --rk2f617: 2rem;
  --rk2f618: 2.25rem;
  --rk2f619: 2.5rem;
  --rk2f61a: 3rem;
  --rk2f61b: 3.5rem;
  --rk2f61c: 4rem;
  --rk2f61d: 4.5rem;
  --rk2f61e: -1.2px;
  --rk2f61f: -1px;
  --rk2f61g: -0.8px;
  --rk2f61h: -0.48px;
  --rk2f61i: -0.4px;
  --rk2f61j: 0.28px;
  --rk2f61k: 0.32px;
  --rk2f61l: 0.36px;
  --rk2f61m: 0.4px;
  --rk2f61n: 0.48px;
  --rk2f61o: 0rem;
  --rk2f61p: 0.125rem;
  --rk2f61q: 0.25rem;
  --rk2f61r: 0.5rem;
  --rk2f61s: 1rem;
  --rk2f61t: 2rem;
  --rk2f61u: 500px;
  --rk2f61v: 50%;
  --rk2f61w: 0rem;
  --rk2f61x: 0.25rem;
  --rk2f61y: 0.5rem;
  --rk2f61z: 0.75rem;
  --rk2f620: 1rem;
  --rk2f621: 1.25rem;
  --rk2f622: 1.5rem;
  --rk2f623: 2rem;
  --rk2f624: 2.5rem;
  --rk2f625: 2.75rem;
  --rk2f626: 3rem;
  --rk2f627: 3.5rem;
  --rk2f628: 4rem;
  --rk2f629: 4.25rem;
  --rk2f62a: 4.5rem;
  --rk2f62b: 5rem;
  --rk2f62c: 6rem;
  --rk2f62d: 7.5rem;
  --rk2f62e: 8rem;
  --rk2f62f: 10rem;
  --rk2f62g: auto;
  --rk2f62h: 100;
  --rk2f62i: 400;
  --rk2f62j: 510;
  --rk2f62k: 512;
  --rk2f62l: 513;
  --rk2f62m: 514;
  --rk2f62n: 515;
  --rk2f62o: 516;
  --rk2f62p: 517;
  --rk2f62q: 518;
  --rk2f62r: 519;
  --rk2f62s: 520;
}