._129pnid0 {
  font-family: var(--rk2f6c);
  color: var(--ds-color-text-primary);
  font-size: var(--rk2f612);
  line-height: var(--rk2f615);
  letter-spacing: var(--rk2f61k);
}
._129pnid0:focus {
  outline: var(--rk2f6w);
}
._129pnid1 {
  font-family: var(--rk2f6d);
  font-size: var(--rk2f61b);
  line-height: var(--rk2f61c);
  letter-spacing: var(--rk2f61e);
  @media (max-width: 1279px) {
    font-size: var(--rk2f61a);
    line-height: var(--rk2f61b);
    letter-spacing: var(--rk2f61f);
  }
  @media (max-width: 1023px) {
    font-size: var(--rk2f619);
    line-height: var(--rk2f61a);
    letter-spacing: var(--rk2f61g);
  }
}
._129pnid2 {
  font-family: var(--rk2f6c);
  font-size: var(--rk2f61b);
  line-height: var(--rk2f61c);
  letter-spacing: var(--rk2f61e);
  @media (max-width: 1279px) {
    font-size: var(--rk2f61a);
    line-height: var(--rk2f61b);
    letter-spacing: var(--rk2f61f);
  }
  @media (max-width: 1023px) {
    font-size: var(--rk2f619);
    line-height: var(--rk2f61a);
    letter-spacing: var(--rk2f61g);
  }
}
._129pnid3 {
  font-family: var(--rk2f6d);
  font-size: var(--rk2f61a);
  line-height: var(--rk2f61b);
  letter-spacing: var(--rk2f61f);
  @media (max-width: 1279px) {
    font-size: var(--rk2f619);
    line-height: var(--rk2f61a);
    letter-spacing: var(--rk2f61g);
  }
  @media (max-width: 1023px) {
    font-size: var(--rk2f617);
    line-height: var(--rk2f619);
    letter-spacing: var(--rk2f61i);
  }
}
._129pnid4 {
  font-family: var(--rk2f6c);
  font-size: var(--rk2f61a);
  line-height: var(--rk2f61b);
  letter-spacing: var(--rk2f61f);
  @media (max-width: 1279px) {
    font-size: var(--rk2f619);
    line-height: var(--rk2f61a);
    letter-spacing: var(--rk2f61g);
  }
  @media (max-width: 1023px) {
    font-size: var(--rk2f617);
    line-height: var(--rk2f619);
    letter-spacing: var(--rk2f61i);
  }
}
._129pnid5 {
  font-family: var(--rk2f6e);
  font-size: var(--rk2f617);
  line-height: var(--rk2f619);
  letter-spacing: var(--rk2f61i);
  @media (max-width: 1023px) {
    font-size: var(--rk2f616);
    line-height: var(--rk2f619);
    letter-spacing: var(--rk2f61i);
  }
}
._129pnid6 {
  font-family: var(--rk2f6d);
  font-size: var(--rk2f617);
  line-height: var(--rk2f619);
  letter-spacing: var(--rk2f61i);
  @media (max-width: 1023px) {
    font-size: var(--rk2f616);
    line-height: var(--rk2f619);
    letter-spacing: var(--rk2f61i);
  }
}
._129pnid7 {
  font-family: var(--rk2f6c);
  font-size: var(--rk2f617);
  line-height: var(--rk2f619);
  letter-spacing: var(--rk2f61i);
  @media (max-width: 1023px) {
    font-size: var(--rk2f616);
    line-height: var(--rk2f619);
    letter-spacing: var(--rk2f61i);
  }
  @media (max-width: 599px) {
    font-size: var(--rk2f616);
    line-height: var(--rk2f619);
    letter-spacing: var(--rk2f61i);
  }
}
._129pnid8 {
  font-family: var(--rk2f6e);
  font-size: var(--rk2f617);
  line-height: var(--rk2f619);
  letter-spacing: var(--rk2f61i);
  @media (max-width: 1279px) {
    font-size: var(--rk2f615);
    line-height: var(--rk2f617);
    letter-spacing: var(--rk2f61n);
  }
  @media (max-width: 1023px) {
    font-size: var(--rk2f614);
    line-height: var(--rk2f616);
    letter-spacing: var(--rk2f61n);
  }
}
._129pnid9 {
  font-family: var(--rk2f6d);
  font-size: var(--rk2f617);
  line-height: var(--rk2f619);
  letter-spacing: var(--rk2f61i);
  @media (max-width: 1279px) {
    font-size: var(--rk2f615);
    line-height: var(--rk2f617);
    letter-spacing: var(--rk2f61n);
  }
  @media (max-width: 1023px) {
    font-size: var(--rk2f614);
    line-height: var(--rk2f616);
    letter-spacing: var(--rk2f61n);
  }
}
._129pnida {
  font-family: var(--rk2f6c);
  font-size: var(--rk2f617);
  line-height: var(--rk2f619);
  letter-spacing: var(--rk2f61i);
  @media (max-width: 1279px) {
    font-size: var(--rk2f615);
    line-height: var(--rk2f617);
    letter-spacing: var(--rk2f61n);
  }
  @media (max-width: 1023px) {
    font-size: var(--rk2f614);
    line-height: var(--rk2f616);
    letter-spacing: var(--rk2f61n);
  }
}
._129pnidb {
  font-family: var(--rk2f6e);
  font-size: var(--rk2f615);
  line-height: var(--rk2f617);
  letter-spacing: var(--rk2f61n);
  @media (max-width: 1279px) {
    font-size: var(--rk2f614);
    line-height: var(--rk2f616);
    letter-spacing: var(--rk2f61n);
  }
  @media (max-width: 1023px) {
    font-size: var(--rk2f613);
    line-height: var(--rk2f615);
    letter-spacing: var(--rk2f61m);
  }
}
._129pnidc {
  font-family: var(--rk2f6d);
  font-size: var(--rk2f615);
  line-height: var(--rk2f617);
  letter-spacing: var(--rk2f61n);
  @media (max-width: 1279px) {
    font-size: var(--rk2f614);
    line-height: var(--rk2f616);
    letter-spacing: var(--rk2f61n);
  }
  @media (max-width: 1023px) {
    font-size: var(--rk2f613);
    line-height: var(--rk2f615);
    letter-spacing: var(--rk2f61m);
  }
}
._129pnidd {
  font-family: var(--rk2f6c);
  font-size: var(--rk2f615);
  line-height: var(--rk2f617);
  letter-spacing: var(--rk2f61n);
  @media (max-width: 1279px) {
    font-size: var(--rk2f614);
    line-height: var(--rk2f616);
    letter-spacing: var(--rk2f61n);
  }
}
._129pnide {
  font-family: var(--rk2f6d);
  font-size: var(--rk2f614);
  line-height: var(--rk2f616);
  letter-spacing: var(--rk2f61n);
}
._129pnidf {
  font-family: var(--rk2f6c);
  font-size: var(--rk2f614);
  line-height: var(--rk2f616);
  letter-spacing: var(--rk2f61n);
  @media (max-width: 1279px) {
    font-size: var(--rk2f614);
    line-height: var(--rk2f616);
    letter-spacing: var(--rk2f61n);
  }
}
._129pnidg {
  font-family: var(--rk2f6d);
  font-size: var(--rk2f613);
  line-height: var(--rk2f615);
  letter-spacing: var(--rk2f61l);
  @media (max-width: 1279px) {
    font-size: var(--rk2f612);
    line-height: var(--rk2f615);
    letter-spacing: var(--rk2f61k);
  }
}
._129pnidh {
  font-family: var(--rk2f6c);
  font-size: var(--rk2f613);
  line-height: var(--rk2f615);
  letter-spacing: var(--rk2f61l);
  @media (max-width: 1279px) {
    font-size: var(--rk2f612);
    line-height: var(--rk2f615);
    letter-spacing: var(--rk2f61k);
  }
}
._129pnidi {
  font-family: var(--rk2f6c);
  font-size: var(--rk2f612);
  line-height: var(--rk2f615);
  letter-spacing: var(--rk2f61k);
}
._129pnidj {
  font-family: var(--rk2f6e);
  font-size: var(--rk2f612);
  line-height: var(--rk2f615);
  letter-spacing: var(--rk2f61k);
}
._129pnidk {
  font-family: var(--rk2f6d);
  font-size: var(--rk2f612);
  line-height: var(--rk2f615);
  letter-spacing: var(--rk2f61k);
}
._129pnidl {
  font-family: var(--rk2f6c);
  font-size: var(--rk2f612);
  line-height: var(--rk2f615);
  letter-spacing: var(--rk2f61k);
}
._129pnidm {
  font-family: var(--rk2f6c);
  font-size: var(--rk2f611);
  line-height: var(--rk2f614);
  letter-spacing: var(--rk2f61j);
}
._129pnidn {
  font-family: var(--rk2f6e);
  font-size: var(--rk2f611);
  line-height: var(--rk2f614);
  letter-spacing: var(--rk2f61j);
}
._129pnido {
  font-family: var(--rk2f6d);
  font-size: var(--rk2f611);
  line-height: var(--rk2f614);
  letter-spacing: var(--rk2f61j);
}
._129pnidp {
  font-family: var(--rk2f6c);
  font-size: var(--rk2f611);
  line-height: var(--rk2f614);
  letter-spacing: var(--rk2f61j);
}
._129pnidq {
  font-family: var(--rk2f6c);
  font-size: var(--rk2f610);
  line-height: var(--rk2f612);
  letter-spacing: var(--rk2f61m);
}
._129pnidr {
  font-family: var(--rk2f6e);
  font-size: var(--rk2f610);
  line-height: var(--rk2f612);
  letter-spacing: var(--rk2f61m);
}
._129pnids {
  font-family: var(--rk2f6d);
  font-size: var(--rk2f610);
  line-height: var(--rk2f612);
  letter-spacing: var(--rk2f61m);
}
._129pnidt {
  font-family: var(--rk2f6e);
  font-size: var(--rk2f610);
  line-height: var(--rk2f612);
  letter-spacing: var(--rk2f61m);
}
._129pnidu {
  font-family: var(--rk2f6d);
  font-size: var(--rk2f610);
  line-height: var(--rk2f612);
  letter-spacing: var(--rk2f61m);
}
._129pnidv {
  font-family: var(--rk2f6c);
  font-size: var(--rk2f610);
  line-height: var(--rk2f612);
  letter-spacing: var(--rk2f61m);
}