:root {
  --ds-color-fill-main: #FFFFFF;
  --ds-color-fill-hover: #EFF2F5;
  --ds-color-fill-disabled: rgba(0, 8, 36, 0.2);
  --ds-color-fill-neutralLowest: #F5F7F9;
  --ds-color-fill-neutralLow: #EFF2F5;
  --ds-color-fill-neutralPure: #E6EAEE;
  --ds-color-fill-neutralHigh: #D4DAE0;
  --ds-color-fill-neutralHover: #B2BAC1;
  --ds-color-fill-brandLowest: #FFEDF2;
  --ds-color-fill-brandPure: #E80070;
  --ds-color-fill-brandHover: #FF5994;
  --ds-color-fill-neutralActive: #B2BAC1;
  --ds-color-fill-brandActive: #FFA4C2;
  --ds-color-fill-interactionActive: #A9A9AD;
  --ds-color-fill-interactionLowest: #5B5C62;
  --ds-color-fill-interactionPure: #22232A;
  --ds-color-fill-interactionHighest: #000000;
  --ds-color-fill-interactionHover: #75767B;
  --ds-color-fill-skeletonPrimary: #E6EAEE;
  --ds-color-fill-skeletonSecondary: #FFFFFF;
  --ds-color-fill-inverseMain: #FFFFFF;
  --ds-color-fill-inverseHover: rgba(255, 255, 255, 0.35);
  --ds-color-fill-inverseDisabled: rgba(255, 255, 255, 0.2);
  --ds-color-border-lowest: rgba(0, 8, 36, 0.15);
  --ds-color-border-low: rgba(0, 8, 36, 0.3);
  --ds-color-border-pure: rgba(0, 8, 36, 0.5);
  --ds-color-border-highest: #000824;
  --ds-color-border-hover: rgba(0, 8, 36, 0.25);
  --ds-color-border-brandMain: #E80070;
  --ds-color-border-brandHover: #FF5994;
  --ds-color-border-brandActive: #FFA4C2;
  --ds-color-border-errorMain: #CE3849;
  --ds-color-border-errorHover: #DF7E89;
  --ds-color-border-errorActive: #EFBFC5;
  --ds-color-border-warningMain: #FF9944;
  --ds-color-border-warningHover: #FFB577;
  --ds-color-border-warningActive: #FFDABB;
  --ds-color-border-successMain: #217A65;
  --ds-color-border-successHover: #2EA88B;
  --ds-color-border-successActive: #B3DFD5;
  --ds-color-border-safetyMain: #3B6AB1;
  --ds-color-border-safetyHover: #759AD3;
  --ds-color-border-safetyActive: #BACCE9;
  --ds-color-border-inverseLowest: rgba(255, 255, 255, 0.15);
  --ds-color-border-inverseLow: rgba(255, 255, 255, 0.3);
  --ds-color-border-inversePure: rgba(255, 255, 255, 0.5);
  --ds-color-border-inverseHighest: #FFFFFF;
  --ds-color-border-inverseHover: rgba(255, 255, 255, 0.35);
  --ds-color-border-inverseBrandMain: #E80070;
  --ds-color-border-inverseBrandHover: #FF5994;
  --ds-color-border-inverseBrandActive: #FFA4C2;
  --ds-color-border-inverseErrorMain: #DB6E7A;
  --ds-color-border-inverseErrorHover: #EBAFB5;
  --ds-color-border-inverseErrorActive: #F2C9CD;
  --ds-color-border-inverseWarningMain: #FFA858;
  --ds-color-border-inverseWarningHover: #FFC99A;
  --ds-color-border-inverseWarningActive: #FFE0C0;
  --ds-color-border-inverseSuccessMain: #3EB397;
  --ds-color-border-inverseSuccessHover: #8BD1C1;
  --ds-color-border-inverseSuccessActive: #B2E0D6;
  --ds-color-border-inverseSafetyMain: #5C89CC;
  --ds-color-border-inverseSafetyHover: #A1BAE2;
  --ds-color-border-inverseSafetyActive: #C1D1EB;
  --ds-color-opacity-transparent: rgba(255, 255, 255, 0);
  --ds-color-opacity-white: rgba(255, 255, 255, 0.7);
  --ds-color-opacity-black: rgba(0, 0, 0, 0.7);
  --ds-color-text-secondary: rgba(0, 8, 36, 0.6);
  --ds-color-text-primary: rgba(0, 8, 36, 0.9);
  --ds-color-text-highlight: #000824;
  --ds-color-text-hover: rgba(0, 8, 36, 0.45);
  --ds-color-text-disabled: rgba(0, 8, 36, 0.35);
  --ds-color-text-brandMain: #E80070;
  --ds-color-text-brandHover: #FF5994;
  --ds-color-text-brandActive: #FFA4C2;
  --ds-color-text-errorMain: #CE3849;
  --ds-color-text-errorHover: #DF7E89;
  --ds-color-text-errorActive: #EFBFC5;
  --ds-color-text-warningMain: #FF9944;
  --ds-color-text-warningHover: #FFB577;
  --ds-color-text-warningActive: #FFDABB;
  --ds-color-text-successMain: #217A65;
  --ds-color-text-successHover: #2EA88B;
  --ds-color-text-successActive: #B3DFD5;
  --ds-color-text-safetyMain: #3B6AB1;
  --ds-color-text-safetyHover: #759AD3;
  --ds-color-text-safetyActive: #BACCE9;
  --ds-color-text-inverseSecondary: rgba(255, 255, 255, 0.6);
  --ds-color-text-inversePrimary: rgba(255, 255, 255, 0.9);
  --ds-color-text-inverseHighlight: #FFFFFF;
  --ds-color-text-inverseHover: rgba(255, 255, 255, 0.4);
  --ds-color-text-inverseDisabled: rgba(255, 255, 255, 0.3);
  --ds-color-text-inverseBrandMain: #E80070;
  --ds-color-text-inverseBrandHover: #FF5994;
  --ds-color-text-inverseBrandActive: #FFA4C2;
  --ds-color-text-inverseErrorMain: #DB6E7A;
  --ds-color-text-inverseErrorHover: #EBAFB5;
  --ds-color-text-inverseErrorActive: #F2C9CD;
  --ds-color-text-inverseWarningMain: #FFA858;
  --ds-color-text-inverseWarningHover: #FFC99A;
  --ds-color-text-inverseWarningActive: #FFE0C0;
  --ds-color-text-inverseSuccessMain: #3EB397;
  --ds-color-text-inverseSuccessHover: #8BD1C1;
  --ds-color-text-inverseSuccessActive: #B2E0D6;
  --ds-color-text-inverseSafetyMain: #5C89CC;
  --ds-color-text-inverseSafetyHover: #A1BAE2;
  --ds-color-text-inverseSafetyActive: #C1D1EB;
  --ds-color-icon-primary: rgba(0, 8, 36, 0.9);
  --ds-color-icon-secondary: rgba(0, 8, 36, 0.6);
  --ds-color-icon-disabled: rgba(0, 8, 36, 0.35);
  --ds-color-icon-hover: rgba(0, 8, 36, 0.45);
  --ds-color-icon-highlight: #000824;
  --ds-color-icon-brandMain: #E80070;
  --ds-color-icon-brandHover: #FF5994;
  --ds-color-icon-brandActive: #FFA4C2;
  --ds-color-icon-errorMain: #CE3849;
  --ds-color-icon-errorHover: #DF7E89;
  --ds-color-icon-errorActive: #EFBFC5;
  --ds-color-icon-warningMain: #FF9944;
  --ds-color-icon-warningHover: #FFB577;
  --ds-color-icon-warningActive: #FFDABB;
  --ds-color-icon-successMain: #217A65;
  --ds-color-icon-successHover: #2EA88B;
  --ds-color-icon-successActive: #B3DFD5;
  --ds-color-icon-safetyMain: #3B6AB1;
  --ds-color-icon-safetyHover: #759AD3;
  --ds-color-icon-safetyActive: #BACCE9;
  --ds-color-icon-inverseSecondary: rgba(255, 255, 255, 0.6);
  --ds-color-icon-inversePrimary: rgba(255, 255, 255, 0.9);
  --ds-color-icon-inverseHighlight: #FFFFFF;
  --ds-color-icon-inverseHover: rgba(255, 255, 255, 0.4);
  --ds-color-icon-inverseDisabled: rgba(255, 255, 255, 0.3);
  --ds-color-icon-inverseBrandMain: #E80070;
  --ds-color-icon-inverseBrandHover: #FF5994;
  --ds-color-icon-inverseBrandActive: #FFA4C2;
  --ds-color-icon-inverseErrorMain: #DB6E7A;
  --ds-color-icon-inverseErrorHover: #EBAFB5;
  --ds-color-icon-inverseErrorActive: #F2C9CD;
  --ds-color-icon-inverseWarningMain: #FFA858;
  --ds-color-icon-inverseWarningHover: #FFC99A;
  --ds-color-icon-inverseWarningActive: #FFE0C0;
  --ds-color-icon-inverseSuccessMain: #3EB397;
  --ds-color-icon-inverseSuccessHover: #8BD1C1;
  --ds-color-icon-inverseSuccessActive: #B2E0D6;
  --ds-color-icon-inverseSafetyMain: #5C89CC;
  --ds-color-icon-inverseSafetyHover: #A1BAE2;
  --ds-color-icon-inverseSafetyActive: #C1D1EB;
  --ds-color-feedback-errorLowest: #FBEEEF;
  --ds-color-feedback-errorLow: #E79FA7;
  --ds-color-feedback-errorPure: #CE3849;
  --ds-color-feedback-errorHigh: #AF2F3E;
  --ds-color-feedback-errorHover: #DF7E89;
  --ds-color-feedback-warningLowest: #FFF5EC;
  --ds-color-feedback-warningLow: #FFC799;
  --ds-color-feedback-warningPure: #FF9944;
  --ds-color-feedback-warningHigh: #C57535;
  --ds-color-feedback-warningHover: #FFB577;
  --ds-color-feedback-successLowest: #EAF6F3;
  --ds-color-feedback-successLow: #8DCFC0;
  --ds-color-feedback-successPure: #217A65;
  --ds-color-feedback-successHigh: #195C4C;
  --ds-color-feedback-successHover: #2EA88B;
  --ds-color-feedback-safetyLowest: #ECF1F9;
  --ds-color-feedback-safetyLow: #97B3DE;
  --ds-color-feedback-safetyPure: #3B6AB1;
  --ds-color-feedback-safetyHigh: #2F548E;
  --ds-color-feedback-safetyHover: #759AD3;
  --ds-color-feedback-inverseErrorLowest: #78212A;
  --ds-color-feedback-inverseErrorLow: #9E2B38;
  --ds-color-feedback-inverseErrorPure: #DB6E7A;
  --ds-color-feedback-inverseErrorHigh: #E4929C;
  --ds-color-feedback-inverseErrorHover: #EBAFB5;
  --ds-color-feedback-inverseWarningLowest: #935927;
  --ds-color-feedback-inverseWarningLow: #E6893D;
  --ds-color-feedback-inverseWarningPure: #FFA858;
  --ds-color-feedback-inverseWarningHigh: #FFB979;
  --ds-color-feedback-inverseWarningHover: #FFC99A;
  --ds-color-feedback-inverseSuccessLowest: #175D4D;
  --ds-color-feedback-inverseSuccessLow: #1F7C67;
  --ds-color-feedback-inverseSuccessPure: #3EB397;
  --ds-color-feedback-inverseSuccessHigh: #65C2AC;
  --ds-color-feedback-inverseSuccessHover: #8BD1C1;
  --ds-color-feedback-inverseSafetyLowest: #264573;
  --ds-color-feedback-inverseSafetyLow: #335C99;
  --ds-color-feedback-inverseSafetyPure: #5C89CC;
  --ds-color-feedback-inverseSafetyHigh: #759AD3;
  --ds-color-feedback-inverseSafetyHover: #A1BAE2;
}